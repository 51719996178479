jQuery('.multiple-items').slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 922,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ]
  });
  
  jQuery('.slider-sync').slick({
   slidesToShow: 1,
   slidesToScroll: 1,
   arrows: false,
   fade: true,
   asNavFor: '.slider-nav'
  });
  
  jQuery('.slider-nav').slick({
   slidesToShow: 4,
   slidesToScroll: 1,
   asNavFor: '.slider-sync',
   dots: true,
   focusOnSelect: true,
   responsive: [
     {
       breakpoint: 992,
       settings: {
         slidesToShow: 1,
         slidesToScroll: 1,
       }
     },
     {
       breakpoint: 768,
       settings: {
         slidesToShow: 1,
         slidesToScroll: 1,
         arrows: true
       }
     }
   ]
  });

  $( document ).ready(function() {

const listItems = Array.from(document.querySelectorAll('.artists_names-title'));
if(listItems) {
listItems.map((item) => {
    item.addEventListener('click', (e) => {
    
      const id = e.currentTarget.dataset.id;
    
      if(id) {
        const elem = document.querySelector(`.${id}`);
        elem.scrollIntoView({behavior: "smooth"})
      }

    })
  })
}

const title = document.querySelector('.names-title.is-mobile');
const drop = document.querySelector('.artists_names');

if(title && drop) {
  title.addEventListener('click', () => {
   drop.classList.toggle('dropdown');
 
  })
}

  })

  